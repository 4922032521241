@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';


$darkRed: #BE1521;
$tangerine: #f28500;

$fontSizeText: $font-size-sm;
$fontColorSizeDrawer: #8F5C2C;


p {
  font-size: $fontSizeText;
}

.Container {
  margin-bottom: 20px;
}
