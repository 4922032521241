@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';
@import '../../../../styles/styles';

.NavigationItem {

  // Mobile (min-width < 500)
  margin: 10px 0;
  width: 100%;
  box-sizing: border-box;
  display: block;
  height: 30px;

  a {
    color: $fontColorSizeDrawer;
    font-size: 1.125rem;
    text-decoration: none;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    &:hover,
    &:active,
    &.active {
      color: #40A4C8;
    }
  }

  p {
    font-size: 1.125rem;
    margin-bottom: 0;
  }

  // Desktop
  @include media-breakpoint-up(lg) {
    margin: 0;
    display: flex;
    height: 100%;
    width: auto;
    align-items: center;

    a {
      color: white;
      height: 100%;
      padding: 16px 10px;
      display: block;
      font-weight: $font-weight-bold;
      box-sizing: border-box;

      &:hover,
      &:active,
      &.active {
        background-color: #e5e5e5;;
        border-bottom: 4px solid #40A4C8;
        color: #555;
      }
    }
  }
}
