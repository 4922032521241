.Logo {
  background-color: transparent;
  // padding: 8px;
  height: 100%;
  box-sizing: border-box;
  border-radius: 5px;

  img {
    max-height: 100%;
    max-width: 100%;
  }
}
