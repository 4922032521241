@import '~bootstrap/scss/bootstrap';
@import './styles/styles.scss';

html, body {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans",  sans-serif;
  max-width: 100%;
  position: relative;
}
