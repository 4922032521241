@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.ChangeLanguage {
  display: flex;
  flex-direction: column;
  height: 100%;

  .Language {
    display: flex;
    height: 40%;
    margin: 2.5% 10px;
    cursor: pointer;
    opacity: 0.5;

    &.Active {
      opacity: 1;
    }

    img {

      width: auto;
      height: 100%;
    }

    p {
      margin-left: 5px;
      color: $white;
      font-weight: $font-weight-bold;
    }
  }
}
