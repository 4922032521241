@import '../../../styles/styles.scss';
@import '~bootstrap/scss/mixins/_breakpoints';

.Modal {
  position: fixed;
  z-index: 500;
  background-color: white;
  width: 90%;
  // max-height: 70%;
  // max-height: 70vh;
  // max-height: calc(100vh - 30%);
  height: 70vh;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px black;
  padding: 16px;
  left: 5%;
  top: 15%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;


  @include media-breakpoint-up(lg) {
    width: 990px;
    left: calc(50% - 495px);
  }

  @include media-breakpoint-only(md) {
    width: 700px;
    left: calc(50% - 350px);
  }

  &.Black {
    background-color: $black;
    border-color: $black;
    color: $white;
  }
}

