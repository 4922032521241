@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.NavigationItems {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  &.ToolbarDisplay {
    flex-direction: row;
  }

  // Desktop
  //@include media-breakpoint-up(lg) {
  //  flex-direction: row;
  //}



}
