.Toolbar {
  height: 60px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-image: linear-gradient(to bottom, #394765, #13213F);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 90;

  nav {
    height: 100%;

    & > div {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .LeftToolbar {
    display: flex;
    height: 100%;

    .Logo {
      height: 80%;
    }
  }


  @media (max-width: 499px) {
    .DesktopOnly {
      display: none;
    }
  }
}
