@import '../../styles/styles.scss';
@import '~bootstrap/scss/mixins/_breakpoints';

.ImageThumbnail {

  &.CardStyle {
    width: auto;
    border: none;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    margin-left: -5px;
    margin-right: -5px;

    img {
      border-radius: 0;
    }
  }

  width: 120px;
  height: auto;
  padding: 5px;
  margin: 10px;
  border: 1px solid $black;
  border-radius: 5px;
  box-shadow: 0 2px 1px $gray-500;
  cursor: pointer;

  p {
    font-size: 0.8rem;
  }

  &:hover {
    opacity: 0.5;
  }

  //@include tabletUp() {
  //  width: 200px;
  //
  //  p {
  //    font-size: 1rem;
  //  }
  //}
  //
  //@include desktopUp() {
  //  width: 350px;
  //}

  img {
    height: auto;
    width: 100%;
    border-radius: 5px;
  }

  p {
    margin: 0;
    text-align: center;
  }

}

.ImageWithinModal {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $black;
  height: 100%;


  img {
    max-width: 100%;
    border-radius: 10px;
    max-height: 100%;
  }
}
