@import '../../styles/styles';
@import '~bootstrap/scss/mixins/breakpoints';

.Home {

  .Content {
    margin: 20px 0;
    text-align: center;
  }

  h1 {
    color: $darkRed;
    font-weight: $font-weight-bold;
  }

  p {
    color: $darkRed;
    font-size: $font-size-base;
  }

  li {
    text-align: left;
  }

  .WorldMap {
    max-width: 100%;
    box-shadow: 0 2px 1px $gray-500;
    border-radius: 10px;
    margin-bottom: 20px;
  }
}
