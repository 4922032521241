@import '../../../styles/styles.scss';
@import '~bootstrap/scss/mixins/_breakpoints';

.MyCard {
  width: 100%;
  min-height: 95%;
  padding: 5px;
  margin: 10px 0;
  border: 1px solid $black;
  border-radius: 5px;
  box-shadow: 0 2px 1px $gray-500;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;


  .Title {
    margin-top: 5px;
    width: 100%;
    text-align: center;
    color: $darkRed;
    font-weight: $font-weight-bold;
  }

  p {
    color: black;
    text-align: center;
  }

  .CountryNote {
    font-size: 12px;
    margin-bottom: 5px;
  }

}
