@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';
@import '../../../styles/styles';

.SideDrawer {
  position: fixed;
  width: 300px;
  max-width: 80%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 200;
  overflow-y: auto;
  background-color: #FFF8DC;
  padding: 16px 16px;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;

  @include media-breakpoint-up(sm) {
    width: 400px;
  }

  &.Open {
    transform: translateX(0);
  }

  &.Close {
    transform: translate(-100%);
  }

  .Logo {
    height: 11%;
    // margin-bottom: 16px;
  }

  .Continent {
    margin-bottom: 20px;

    .TitleContinent {
      display: flex;
      align-items: center;

      h4 {
        color: $fontColorSizeDrawer;
        font-weight: bold;
        margin: 0 5px 0 0;
      }

      .IconTitle {
        color: $fontColorSizeDrawer;
      }
    }
  }
}
